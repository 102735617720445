.MaxWidthContainer {
  max-width: 1200px;
  margin: 0 auto;
}

.MoodzhubTop {
  padding-top: 70px;

  @media only screen and (max-width: 991px) {
    padding: 0;
  }
}

.MoodzhubAnchor {
  scroll-margin-top: 100px;
}

.EN {
  font-family: "Jost";
  line-height: 1.6;

  &-header {
    font-size: 1.3rem;
  }

  &-text {
    margin-top: 5px;
    font-size: 1.15rem;
    font-weight: 500;
  }

  @media only screen and (max-width: 991px) {
    line-height: 1.5;
    &-header {
      font-size: 1.15rem;
    }
    &-text {
      font-size: 1rem;
    }
  }
}

.MM {
  font-family: "Pyidaungsu";
  line-height: 2;

  &-header {
    font-size: 1.2rem;
  }
  &-text {
    font-size: 1.1rem;
    font-weight: 500;
    margin-top: 5px;
  }
  @media only screen and (max-width: 991px) {
    line-height: 1.85;
    &-header {
      font-size: 1.1rem;
    }
    &-text {
      font-size: 1rem;
    }
  }
}

.PrimaryText {
  color: var(--primary-text-color);
}

.MainLogo {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: -20px;

  .LogoImage {
    width: 200px;
  }
}

.MaxContainer {
  background-color: var(--main-color);
  padding: 20px 12px 20px 12px;
}

.MainHeaderContainer {
  background-color: var(--main-color);
  clip-path: polygon(0px 0px, 100% 0px, 99.89% 93.75%, 1px 76.25%);
}

.MainContentContainer {
  background-color: var(--main-color);
}

.AppDetails {
  padding: 0 20px;
  margin: 0 auto;
}

.AppBrandStory {
  margin-bottom: 80px;
}

.AppMascotStory {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  .AppMascotImage {
    width: 100%;
    transition: transform 0.25s ease-in-out;

    &:hover {
      transform: scale(1.05);
      transition: transform 0.35s ease-in-out;
    }
  }

  @media only screen and (max-width: 767px) {
    grid-template-rows: auto;
    grid-template-columns: none;
  }
}

.AppSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  margin: 50px 20px 0;

  .App {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (min-width: 1200px) {
      &:nth-of-type(2) {
        margin-top: -20px;
      }
    }
  }

  .AppScreens {
    display: flex;
    justify-content: center;

    .Screen {
      width: 200px;
      border-radius: 20px;

      &:nth-of-type(1),
      &:nth-of-type(3) {
        transform: scale(0.95) translateY(23px);
      }
      &:nth-of-type(1) {
        margin-right: -50px;
      }
      &:nth-of-type(2) {
        z-index: 1;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
          rgba(0, 0, 0, 0.24) 0px 1px 2px;
        transform: scale(1.05);
      }
      &:nth-of-type(3) {
        margin-left: -50px;
      }

      $mobileTransform: scale(0.95) translateY(8px);

      @media only screen and (max-width: 1199px) {
        width: auto;
        height: 280px;

        &:nth-of-type(1) {
          transform: $mobileTransform rotate(-15deg);
        }
        &:nth-of-type(3) {
          transform: $mobileTransform rotate(15deg);
        }
        &:nth-of-type(1) {
          margin-right: -70px;
        }
        &:nth-of-type(3) {
          margin-left: -70px;
        }
      }

      @media only screen and (max-width: 767px) {
        height: 180px;

        &:nth-of-type(1) {
          transform: $mobileTransform rotate(-12deg);
        }
        &:nth-of-type(3) {
          transform: $mobileTransform rotate(12deg);
        }
        &:nth-of-type(1) {
          margin-right: -60px;
        }
        &:nth-of-type(3) {
          margin-left: -60px;
        }
      }
    }
  }

  .AppLink {
    margin-top: -30px;
    & > img {
      width: 200px;
    }
    @media only screen and (max-width: 1199px) {
      margin-top: -20px;
      & > img {
        width: 160px;
      }
    }
    @media only screen and (max-width: 767px) {
      margin-top: -20px;
      & > img {
        width: 120px;
      }
    }
  }

  .AppStateSection {
    margin-top: 40px;
    text-align: center;
    padding: 6px 12px;
    color: var(--main-bg-color);
    background-color: #000;
    line-height: 1.25;
    font-size: 1.25rem;
    font-weight: 500;
    border-radius: 8px;

    @media only screen and (max-width: 1199px) {
      margin-top: 35px;
      font-size: 1rem;
      padding: 4px 12px;
    }

    @media only screen and (max-width: 767px) {
      margin-top: 20px;
      font-size: 0.8rem;
    }
  }

  @media only screen and (max-width: 767px) {
    column-gap: 10px;
    margin-top: 30px;
  }
}

.WebApp {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  .App {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .AppScreens {
    display: flex;
    justify-content: center;

    .Screen {
      height: 350px;
      border-radius: 20px;

      &:nth-of-type(1),
      &:nth-of-type(3) {
        transform: scale(0.95) translateY(23px);
      }
      &:nth-of-type(1) {
        margin-right: -50px;
      }
      &:nth-of-type(2) {
        z-index: 1;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
          rgba(0, 0, 0, 0.24) 0px 1px 2px;
        transform: scale(1.05);
      }
      &:nth-of-type(3) {
        margin-left: -50px;
      }

      $mobileTransform: scale(0.95) translateY(8px);

      @media only screen and (max-width: 1199px) {
        width: auto;
        height: 280px;

        &:nth-of-type(1) {
          transform: $mobileTransform rotate(-15deg);
        }
        &:nth-of-type(3) {
          transform: $mobileTransform rotate(15deg);
        }
        &:nth-of-type(1) {
          margin-right: -70px;
        }
        &:nth-of-type(3) {
          margin-left: -70px;
        }
      }

      @media only screen and (max-width: 767px) {
        height: 200px;

        &:nth-of-type(1) {
          transform: $mobileTransform rotate(-12deg);
        }
        &:nth-of-type(3) {
          transform: $mobileTransform rotate(12deg);
        }
        &:nth-of-type(1) {
          margin-right: -60px;
        }
        &:nth-of-type(3) {
          margin-left: -60px;
        }
      }

      @media only screen and (max-width: 767px) {
        height: 140px;
      }
    }
  }

  .AppStateSection {
    margin-top: 40px;
    text-align: center;
    padding: 12px 20px;
    color: var(--main-bg-color);
    background-color: #000;
    line-height: 1.25;
    font-size: 1.25rem;
    font-weight: 500;
    border-radius: 8px;

    @media only screen and (max-width: 1199px) {
      margin-top: 35px;
      font-size: 1rem;
      padding: 10px 18px;
    }

    @media only screen and (max-width: 767px) {
      margin-top: 20px;
      font-size: 0.8rem;
    }

    &:hover {
      animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    }
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
}

.MoodzhubFAQ {
  padding: 30px 20px 40px;

  .LanguageSwitch {
    display: inline-grid;
    grid-template-columns: repeat(3, auto);
    column-gap: 8px;
    align-items: center;
    margin: 30px 0;
  }

  .Language {
    color: var(--primary-text-color);
    font-weight: 500;
    margin-top: 0;
    padding: 6px 0;
    cursor: pointer;
    transition: background-color 0.25s, color 0.25s, padding 0.125s ease-in-out;

    &.Active {
      border-radius: 10px;
      padding: 6px 15px;
      background-color: var(--main-color);
      color: var(--secondary-text-color);
    }
  }

  .FaqItem {
    padding: 20px 0;
    border-bottom: 1px solid var(--main-color);
    display: flex;

    &:first-of-type {
      padding-top: 0;
    }
    &:last-of-type {
      border: 0;
    }

    &__icon {
      margin-top: 6px;
      margin-right: 20px;
      width: 20px;
      cursor: pointer;
    }

    &__title {
      cursor: pointer;
      user-select: none;
      margin-bottom: 8px;
      font-weight: 600;
      color: var(--primary-text-color);
      & > * {
        margin: 0;
      }
    }

    &__body {
      overflow: hidden;
      margin: 0;
      color: var(--primary-text-color);
      opacity: 1;
      height: auto;
      transition: opacity 0.3s ease-in-out;

      &-collapsed {
        opacity: 0;
        height: 0;
      }

      & > * {
        margin: 0;
      }
    }
  }
}

.MoodzhubFooter {
  border-top: 2px solid var(--main-color);
  padding: 10px 20px;
  font-weight: 500;
  font-size: 1.05rem;

  &Block {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
      align-items: initial;
    }
  }

  .ContactUsBlock {
    display: flex;
    align-items: center;

    &:first-of-type {
      margin-bottom: 8px;
    }

    & > a:not(:last-child) {
      margin-right: 8px;
      cursor: pointer;
    }

    & > a[href^="mailto:"] {
      transition: color 0.2s ease-in;
    }

    & > a:hover[href^="mailto:"] {
      color: var(--secondary-text-color);
    }
  }

  .SocialMediaIcon {
    width: 26px;
    border-radius: 8px;
  }

  .CopyRightBlock {
    font-size: 0.85rem;
    font-weight: 500;
    text-align: center;
    padding: 10px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 0.95rem;
  }
}

.privacy_link {
  color: #454CE3;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.25s ease-in-out;
  text-decoration: underline;
}
