:root {
  --main-color: #e7c8bc;
  --primary-text-color: #753425;
  --secondary-text-color: #47aa9c;
  --main-bg-color: #f3f3f3;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

@font-face {
  font-family: "Pyidaungsu";
  src: url("../fonts/mm/Pyidaungsu-1.8.3_Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Pyidaungsu";
  src: url("../fonts/mm/Pyidaungsu-1.8.3_Bold.ttf") format("truetype");
  font-weight: bold;
}

html {
  scroll-behavior: smooth;
  background: var(--main-bg-color);
}

body {
  font-family: "Jost", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a,
a:hover,
a:active,
a:focus {
  color: inherit;
  text-decoration: none;
}
