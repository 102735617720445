.NavigationBar {
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
  padding: 20px;
  background: #f3f3f3;
  z-index: 5;
  text-align: right;
  // transition: transform 0.5s ease;

  @media only screen and (max-width: 991px) {
    width: 100%;
    height: 120%;
    position: absolute;
    transform: translateX(110%);
    transition: transform 0.5s ease;
    display: none;
  }

  &.is-active {
    display: block;
    transform: translateX(0);
  }
}

.NavigationMenu {
  display: inline-grid;
  grid-template-columns: repeat(5, auto);
  column-gap: 50px;
  justify-items: center;

  @media only screen and (max-width: 991px) {
    display: grid;
    grid-template-columns: none;
    grid-template-rows: repeat(5, 1fr);
    row-gap: 30px;
    margin-top: 50px;
  }
}

.NavigationTitle {
  font-family: "Jost";
  font-size: 1.25rem;
  color: var(--primary-text-color);
  font-weight: 500;
  margin: 0;
  transition: transform 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);

  &:hover {
    transform: translateY(-3px);
  }
}

.hamburger {
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 15px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 10;

  &-box {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
  }

  &-inner {
    position: absolute;
    width: 40px;
    height: 4px;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition-duration: 0.22s;
    transition-property: transform;
    border-radius: 4px;
    background-color: var(--primary-text-color);
    cursor: pointer;

    top: 50%;
    display: block;
    margin-top: -2px;

    &:before,
    &:after {
      display: block;
      content: "";
      position: absolute;
      width: 40px;
      height: 4px;
      border-radius: 4px;
      background-color: var(--primary-text-color);
    }

    &:before {
      top: -10px;
      transition: top 0.1s ease-in 0.25s, opacity 0.1s ease-in;
    }
    &:after {
      transition: bottom 0.1s ease-in 0.25s,
        transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      bottom: -10px;
    }
  }

  &.is-active {
    .hamburger-inner {
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: rotate(225deg);
      transition-duration: 0.22s;
      background-color: var(--primary-text-color);

      &:before,
      &:after {
        background-color: var(--primary-text-color);
      }
      &:before {
        top: 0;
        transition: top 0.1s ease-out, opacity 0.1s ease-out 0.12s;
        opacity: 0;
      }

      &:after {
        bottom: 0;
        transition: bottom 0.1s ease-out,
          transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
        transform: rotate(-90deg);
      }
    }
  }
}
